<template>
  <div class="wrap">
	<my-header></my-header>
	<div class="section">
		<div class="banner">
			<p>联系我们</p>
			<div>感谢您的关注，您可以通过一下方式与我们取得联系</div>
		</div>
		<div class="content">
			<p class="detail_title">联系方式</p>
			<div class="contact_img">
				<div><img src="" alt=""></div>
				<div><img src="" alt=""></div>
			</div>
			<div class="contact_hint">
				<div>感谢您对宏瓴科技的支持与关注，</div>
				<div>请留下您的宝贵信息，我们会第一时间联系您～</div>
			</div>
		</div>
	</div>
	<my-footer></my-footer>
  </div>
</template>

<script>
// @ is an alias to /src  
import MyHeader from '@/components/MyHeader.vue'
import MyFooter from '@/components/MyFooter.vue'

export default {
  name: 'ContactUs',
  components: {
    MyHeader,MyFooter
  },
  data(){
	  return{
	  }
  },
  methods:{
  },
  mounted() {
  }
}
</script>
<style scoped>
	.banner{
		width: 100%;
		height: 5.76rem;
		padding:1.8rem 3.6rem 0;
		font-size: 0.22rem;
		font-weight: 300;
		background: url(../../public/images/dynamic/banner.png) no-repeat;
		background-size: 100% 100%;
		line-height: 2;
	}
	.banner p{
		font-size: 0.54rem;
		line-height:1.39 ;
		margin-bottom: 0.28rem;
	}
	.content{
		padding:1rem 3.6rem;
		color:#333;
	}
	.detail_title{
		font-size: 0.32rem;
		font-weight: 600;
		text-align: center;
	}
	.contact_img{
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top:0.5rem;
	}
	.contact_img div{
		width: 5.5rem;
	}
	.contact_hint{
		margin-top:1rem;
		text-align:center;
		font-size: 0.26rem;
		line-height: 2;
	}
</style>
